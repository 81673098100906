import React from "react";
import { Link } from "react-router-dom";
import classes from "./Footer.module.css";
import { SocialIcon } from "react-social-icons";
import send from "../../Images and Icons/send.svg";

const Footer = () => {
  const scrollToTheTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div>
          <h4>Your Study Path</h4>
          <p>
            Your Sudy Path Limited <br />
            315, Borno Way, Ebute Metta, Lagos
          </p>
          <a href="tel:+2349018147683">+234 901 814 7683</a>
          <a href="mailto: info@yourstudypath.com">info@yourstudypath.com</a>
          <div className={classes.socialSection}>
            <SocialIcon
              url="https://twitter.com/yourstudypath"
              fgColor="#ffffff"
              bgColor="#3E5D9A"
              target="_blank"
            />
            <SocialIcon
              url="https://facebook.com/yourstudypath"
              fgColor="#ffffff"
              bgColor="#3E5D9A"
              target="_blank"
            />
            <SocialIcon
              url="https://www.linkedin.com/company/yourstudypath/"
              fgColor="#ffffff"
              bgColor="#3E5D9A"
              target="_blank"
            />
            <SocialIcon
              url="https://youtube.com/channel/UCBllaHLv5L8dIwTKKpRNseQ"
              fgColor="#ffffff"
              bgColor="#3E5D9A"
              target="_blank"
              className={classes.socialIcon}
            />
          </div>
        </div>
        <div>
          <h3>Company</h3>
          <Link to="/about-us" onClick={scrollToTheTop}>
            About us
          </Link>
          <Link to="/plan-and-pricing" onClick={scrollToTheTop}>
            Plan & Pricing
          </Link>
          <Link to="#0" onClick={scrollToTheTop}>
            {" "}
            Testimonials
          </Link>
        </div>
        <div>
          <h3>Support</h3>
          <Link to="/terms-of-service" onClick={scrollToTheTop}>
            Terms of service
          </Link>
          <Link to="/privacy-policy" onClick={scrollToTheTop}>
            Privacy policy
          </Link>
          <Link to="/faqs" onClick={scrollToTheTop}>
            FAQs
          </Link>
        </div>
        <div>
          <h3>Stay up to date</h3>
          <div>
            <input type="text" placeholder="Your email address" />
            <img src={send} alt="Send" />
          </div>
        </div>
      </div>
      <div className={classes.copyrightsection}>
        <p> &copy; 2022 YourStudyPath. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
