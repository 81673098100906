import React from "react";
import classes from "./Statistics.module.css";

const Statistics = () => {
  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div>
          <p>20 +</p>
          <p>Tutors</p>
        </div>
        <div>
          <p>500 +</p>
          <p>Students</p>
        </div>
        <div>
          <p>20 +</p>
          <p>Subjects</p>
        </div>
        <div>
          <p>1000 +</p>
          <p>Materials</p>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
