import React, { useEffect } from "react";
import classes from "./Investors.module.css";
import Aos from "aos";
import "aos/dist/aos.css";

const Investors = () => {
  // Effects
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  const imageLinks = [
    "https://res.cloudinary.com/drybwe5na/image/upload/v1679056725/Logo_LibertyPay_1_u5cptm.svg",
    "https://res.cloudinary.com/drybwe5na/image/upload/v1679056725/agro_mall_logo_rgrzce.svg",
    "https://res.cloudinary.com/drybwe5na/image/upload/v1679056726/young_ranchers_logo_bcebxh.svg",
    "https://res.cloudinary.com/drybwe5na/image/upload/v1679056725/Veritasi_Logo_PNG_1_cxeawz.svg",
  ];
  return (
    <section className={classes.container}>
      <div className={classes.div}>Clients we have worked with</div>
      <div className={classes.imageSection}>
        {imageLinks.map((image, i) => {
          return (
            <img key={i} src={image} alt="Client Logo" data-aos="fade-up" />
          );
        })}
      </div>
    </section>
  );
};

export default Investors;
