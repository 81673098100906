import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import SignUp from "./Components/Sign Up/SignUp";
import LandingPage2 from "./Components/LandingPage 2.0/LandingPage2";
import AboutUs from "./Components/LandingPage 2.0/AboutUs/AboutUs";
import PlanAndPricing from "./Components/LandingPage 2.0/PlanAndPricing/PlanAndPricing";
import TermsOfservice from "./Components/LandingPage 2.0/TermsOfService/TermsOfService";
import FAQs from "./Components/LandingPage 2.0/FAQs/FAQs";
import PrivacyPolicyContainer from "./Components/LandingPage 2.0/PrivacyPolicyContainer/PrivacyPolicyContainer";
import HowItWorksPage from "./Components/LandingPage 2.0/HowItWorksPage/HowItWorksPage";

function App() {
  return (
    <Router>
      <Routes>
        {/* <SignUp /> */}
        {/* <LandingPage /> */}
        {/* <LandingPage2 /> */}
        <Route path="/" exact element={<LandingPage2 />} />
        <Route path="/about-us" exact element={<AboutUs />} />
        <Route path="/plan-and-pricing" exact element={<PlanAndPricing />} />
        <Route path="/terms-of-service" exact element={<TermsOfservice />} />
        <Route path="/faqs" exact element={<FAQs />} />
        <Route
          path="/privacy-policy"
          exact
          element={<PrivacyPolicyContainer />}
        />

        <Route path="/how-it-works" exact element={<HowItWorksPage />} />
        {/* <Route path="/learn-with-us" exact element={<PageMainContainer />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
