import React, { useEffect } from "react";
import { termsofService } from "../../../Utilities/TermsOfService";
import LandingPageLayout from "../LandingPageLayout/LandingPageLayout";
import classes from "./TermsOfService.module.css";
import Aos from "aos";
import "aos/dist/aos.css";

const TermsOfservice = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <LandingPageLayout>
      <div className={classes.container}>
        <div className={classes.containerInner}>
          <div className={classes.header}>
            <div>
              <h4>Terms of Service</h4>
            </div>
          </div>
          <div className={classes.body}>
            <div className={classes.bodyInner}>
              {termsofService.map((data, i) => {
                return (
                  <div className={classes.container} key={i}>
                    <div className={classes.headerMain}>
                      <h4>{data.title}</h4>
                      {data.descriptions.map((data, i) => {
                        return <p key={i}>{data}</p>;
                      })}
                    </div>
                    <div className={classes.bodyMain}>
                      {data.subHeadings.map((data, i) => {
                        return (
                          <div key={i} className={classes.subHeading}>
                            <h4 data-aos="fade-up">{data.title}</h4>
                            {data.paragraphs.map((data, i) => {
                              return <p key={i}>{data}</p>;
                            })}
                            {data.orderedLists.map((data, i) => {
                              return (
                                <div className={classes.orderedLists}>
                                  <div>{`${i + 1}.`}</div>
                                  <div>{data}</div>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                      <p>{data.footer}</p>
                      <div className={classes.attention}>
                        <h4>Attention</h4>
                        <address>315, Borno Way, Ebute Metta, Lagos</address>
                        <a href="tel:+2349018147683">
                          Telephone: +234 901 814 7683
                        </a>
                        <a href="mailto: info@yourstudypath.com">
                          Email Address: info@yourstudypath.com
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </LandingPageLayout>
  );
};

export default TermsOfservice;
