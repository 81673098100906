import React, { useEffect } from "react";
import classes from "./IntellectusAd.module.css";
import Aos from "aos";
import "aos/dist/aos.css";

const IntellectusAd = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div data-aos="zoom-in-down">
          {/* <iframe
            src="https://www.youtube.com/embed/WnkeGU6sDSM?autoplay=1&version=3&enablejsapi=1&showinfo=0&controls=0&rel=0&showinfo=0&disablekb=1&iv_load_policy=3&modestbranding=0"
            modestbranding="0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            sandbox="allow-scripts allow-same-origin"
          ></iframe> */}

          <iframe
            src="https://www.youtube.com/embed/7vgIbvJwnG4"
            modestbranding="0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            sandbox="allow-scripts allow-same-origin"
          ></iframe>
        </div>
        <div data-aos="zoom-in-up">
          <h4>Ace Your JAMB & WAEC Exams with Our Expert Tutors</h4>
          <p>
            Welcome to Your Study Path, the premier destination for secondary
            school students preparing for the JAMB and WAEC exams.
          </p>
          <p>
            Our tutoring program is designed to help students like you unlock
            their academic potential and achieve success in these crucial exams.
            Our class includes practice exams, study materials, and regular
            assessments to ensure you are on track for success.
          </p>
          <button
            onClick={() => {
              window.open(
                "https://app.yourstudypath.com/question-bank",
                "_blank"
              );
            }}
          >
            Start Free Trial
          </button>
        </div>
      </div>
    </div>
  );
};

export default IntellectusAd;
