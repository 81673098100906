import React from "react";
import HeroSection from "./HeroSection/HeroSection";
import IntellectusAd from "./IntellectusAd/IntellectusAd";
import Investors from "./Investors/Investors";
import classes from "./LandingPage2.module.css";
import LandingPageLayout from "./LandingPageLayout/LandingPageLayout";
import MailingList from "./MailingList/MailingList";
import OurFearuresSection from "./OurFearuresSection/OurFearuresSection";
import PopularCourses from "./PopularCourses/PopularCourses";
import Statistics from "./Statistics/Statistics";
import Testimonials from "./Testimonials/Testimonials";

const LandingPage2 = () => {
  return (
    <div className={classes.container}>
      <LandingPageLayout>
        <div className={classes.heroSection}>
          <HeroSection />
          <div className={classes.statistic}>
            <Statistics />
          </div>
        </div>
        <IntellectusAd />
        {/* <SummarySection /> */}
        <OurFearuresSection />
        <PopularCourses />
        <Testimonials />

        <MailingList />
        <Investors />
      </LandingPageLayout>
    </div>
  );
};

export default LandingPage2;
