import React from "react";
import classes from "./LandingPageLayout.module.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const LandingPageLayout = (props) => {
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Header />
      </div>
      <div className={classes.body}>
        {props.children}
        <div className={classes.footer}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default LandingPageLayout;
