import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import classes from "./PopularCourseModal.module.css";

const PopularCourseModal = (props) => {
  return (
    <div className={classes.container}>
      <iframe
        src={`https://player.vimeo.com/video/${props.activeLinkId}`}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
        title="YSP Video Snippet"
      ></iframe>

      <i
        onClick={() => {
          props.onClick();
        }}
      >
        <FontAwesomeIcon icon={faXmark} />
      </i>
    </div>
  );
};

export default PopularCourseModal;
