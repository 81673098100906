import React from "react";
import classes from "./OurBoardOfAdvisory.module.css";
import drAdetolaSalauWoman from "../../Images and Icons/Dr Adetola Salau Woman.svg";
import adetolaNola from "../../Images and Icons/Adetola Nola.svg";
import ayaZaghnin from "../../Images and Icons/Aya Zaghnin.svg";
import drAdetolaSalau from "../../Images and Icons/Dr Adetola Salau.svg";
import idemudiaDimaOkojie from "../../Images and Icons/Idemudia Dima-okojie.svg";
import omotayoOshiga from "../../Images and Icons/omotayoOshiga.jpeg";
import { v4 } from "uuid";

const OurBoardOfAdvisory = () => {
  const boardOfAdvisory = [
    {
      id: v4(),
      image: drAdetolaSalauWoman,
      name: "Dr Adetola Salau",
      capacity: "Education Technology/Regulations",
    },
    {
      id: v4(),
      image: adetolaNola,
      name: "Adetola Nola",
      capacity: "Strategy",
    },
    {
      id: v4(),
      image: ayaZaghnin,
      name: "Aya Zaghnin",
      capacity: "Finance & Fund raising",
    },
    {
      id: v4(),
      image: omotayoOshiga,
      name: "Dr Omotayo Oshiga",
      capacity: "Electrical Engineering",
    },
    {
      id: v4(),
      image: drAdetolaSalau,
      name: "Otunba Lekan Saka",
      capacity: "Operations",
    },
    {
      id: v4(),
      image: idemudiaDimaOkojie,
      name: "Idemudia Dima-Okojie",
      capacity: "Marketing",
    },
  ];

  const ourLeaderShip = [
    {
      id: v4(),
      image:
        "https://res.cloudinary.com/drybwe5na/image/upload/v1695280181/DanielEgunjobi_nzgtuo.jpg",
      name: "Daniel Egunjobi",
      capacity: "Founder",
    },
    {
      id: v4(),
      image:
        "https://res.cloudinary.com/drybwe5na/image/upload/v1695280183/InnocentUsigbe_hn0zbb.jpg",
      name: "Innocent Usigbe",
      capacity: "Co-Founder",
    },
  ];
  return (
    <div className={classes.container}>
      <div className={classes.header}>Our Leadership </div>
      <div className={classes.boardOfAdvisory}>
        {ourLeaderShip.map((data) => {
          return (
            <div className={classes.advisor} key={data.id}>
              <img src={data.image} alt={data.name} />
              <div>
                <p>{data.name}</p>
                <p>{data.capacity}</p>
              </div>
            </div>
          );
        })}
      </div>

      <div className={classes.header}>Our Board of Advisory </div>
      <div className={classes.boardOfAdvisory}>
        {boardOfAdvisory.map((data) => {
          return (
            <div className={classes.advisor} key={data.id}>
              <img src={data.image} alt={data.name} loading="lazy" />
              <div>
                <p>{data.name}</p>
                <p>{data.capacity}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OurBoardOfAdvisory;
