import React, { useEffect } from "react";
import classes from "./MailingList.module.css";
import DownloadSection from "../../Images and Icons/DownloadSection.png";
import Aos from "aos";
import "aos/dist/aos.css";

const MailingList = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div
      className={classes.container}
      data-aos="fade-up"
      data-aos-anchor-placement="top-center"
    >
      <div className={classes.subContainer}>
        <div className={classes.textSection}>
          <h1>
            Get <span className={classes.ysp}>Your Study Path</span> App for
            free !
          </h1>
          <p>
            Your study path app will be available on google play store and the
            app store soon
          </p>
          <span className={classes.comingSoon}>Coming Soon...</span>
          <div className={classes.subscribeSection}>
            {/* <input type="text" placeholder="Enter your email address" />
            <button>Subscribe</button> */}
          </div>
        </div>
        <div className={classes.imageSection}>
          <img src={DownloadSection} alt="Download Section" />
        </div>
      </div>
    </div>
  );
};

export default MailingList;
