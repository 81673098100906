export const privacyPolicy = [
  {
    title: "The Information We Collect",
    paragraph:
      "We collect the following information: name, data of birth, residential and contact address, telephone number, email address, marital status, nationality, gender, tax identity number, bank details, posts on social networking information on family members, medical information, educational background, genetic data, biometric data, identity number, location, photograph, IP address, MAC address, IMEI number, IMSI number and other information relevant. ",
    orderedLists: [],
    footer: "",
  },
  {
    title: "How We Collect and Use Your Data.",
    paragraph: "",
    orderedLists: [
      "YSPL collects the above-mentioned information using forms, email and physical requests, cookies, JWT, web tokens.",
      "When you send email or other communications to YSPL, we may retain those communications in order to process your inquiries, respond to your requests and improve our services. When you access YSPL’s services, our servers automatically record information that your browser sends whenever you visit a website.",
      " We use the above-mentioned information for due diligence, transaction advisory, legal advisory, litigation, regulatory compliance, marketing, business development, listing on legal directories, publicity, human resources management, recruitment, events planning and hosting, in-house security, research, analysis and user registration.",
    ],
    footer: "",
  },
  {
    title: "Consent and Access Rights.",
    paragraph: "",
    orderedLists: [
      "We require your consent for the processing of your data and shall obtain your consent to individual matters, where any document deals with other matters. If we use this information in a manner different than the purpose for which it was collected, then we will ask for your consent prior to such use.",
      "No Consent shall be sought, given or accepted in any circumstance that may engender direct or indirect propagation of atrocities, hate, child rights violation, criminal acts and anti-social conducts.",
      "You may withdraw your consent at any time and may request access to your personal information in our possession. We can, however, deny you access to the information where we determine that your request is unreasonable.",
      "You reserve the right to request the modification or amendment of your personal data in our possession.",
      "In all cases of access or modification / amendment of personal information, we shall request sufficient identification to enable us confirm that you are the owner of the data sought to be accessed or modified / amended.",
    ],
    footer: "",
  },
  {
    title: "Personal Data Protection Principles.",
    paragraph:
      "When we process your personal data, we are guided by the following principles, which require personal data to be:",
    orderedLists: [
      "processed lawfully, fairly, in a transparent manner and with respect for the dignity of the human person.",
      "collected only for specified, explicit and legitimate purposes and not further processed in a manner incompatible with those purposes.",
      "adequate, relevant and limited to what is necessary in relation to the purposes for which it is Processed.",
      "accurate and where necessary kept up to date.",
      "removed or not kept in a form which permits identification of data subject for longer than is necessary for the purposes for which the personal data is processed.",
      "processed in a manner that ensures its security, using appropriate technical and organisational measures to protect against unauthorised or unlawful Processing and against accidental loss, destruction or damage.",
    ],
    footer: "",
  },
  {
    title: "User Responsibility",
    paragraph:
      "You are required to familiarise yourself with this policy and to ensure that the information you provide to us is complete, accurate and up to date.",
    orderedLists: [],
    footer: "",
  },
  {
    title: "Data Security.",
    paragraph: "",
    orderedLists: [
      "YSPL implements and maintains appropriate safeguards to protect personal data, taking into account in particular the risks to you, presented by unauthorised or unlawful processing or accidental loss, destruction of, or damage to their personal data.",
      "Safeguarding will include the use of encryption and pseudonymisation where appropriate. It also includes protecting the confidentiality (i.e. that only those who need to know and are authorised to use personal data have access to it), integrity and availability of the personal data. we regularly evaluate and test the effectiveness of those safeguards to ensure security of our processing of personal data.",
    ],
    footer: "",
  },
  {
    title: "Data Security.",
    paragraph:
      "YSPL only shares personal information with other companies or individuals in the following limited circumstances:",
    orderedLists: [
      "We have your consent.",
      "We provide such information to other professional advisers or other trusted businesses or persons for the purpose of processing personal information on our behalf. We require that these parties agree to process such information based on our instructions and in compliance with this Privacy Policy and any other appropriate confidentiality and security measures.",
      "We have a good faith belief that access, use, preservation or disclosure of such information is reasonably necessary to (a) satisfy any applicable law, regulation, legal process or enforceable governmental request, (b) enforce applicable terms of service, including investigation of potential violations thereof, (c) detect, prevent, or otherwise address fraud, security or technical issues, or ",
      "protect against imminent harm to the rights, property or safety of YSPL, its users or the public as required or permitted by law.",
      "YSPL is at all times, responsible for the security and appropriate use of that data as long as it remains with YSPL.",
    ],
    footer: "",
  },
  {
    title: "Violation of Privacy.",
    paragraph: "",
    orderedLists: [
      "We have put in place procedures to deal with any suspected personal data breach and will notify you of any personal data breach and let you know the steps we have taken to remedy the breach and the security measures we have applied to render your personal data unintelligible. ",
      "All suspected breach of personal data will be remedied within one (1) calendar month from the date of the report of the breach.",
      "If you know or suspect that a personal data breach has occurred, you should immediately contact the YSPL team at info@yourstudypath.com.",
      "protect against imminent harm to the rights, property or safety of YSPL, its users or the public as required or permitted by law.",
      "YSPL is at all times, responsible for the security and appropriate use of that data as long as it remains with YSPL.",
    ],
    footer: "",
  },

  {
    title: "",
    paragraph:
      "YSPL will not be responsible for any personal data breach which occurs as a result of:",
    orderedLists: [
      "an event which is beyond the control of YSPL;",
      "an act or threats of terrorism;",
      "an act of God (such as, but not limited to fires, explosions, earthquakes, drought, tidal waves and floods) which compromises YSPL’s data protection measures;",
      "war, hostilities (whether war be declared or not), invasion, act of foreign enemies, mobilisation, requisition, or embargo;",
      "rebellion, revolution, insurrection, or military or usurped power, or civil war which compromises YSPL’s data protection measures;",
      "the transfer of your personal data to a third party on your instructions; and",
      "the use of your personal data by a third party designated by you.",
    ],
    footer: "",
  },
  {
    title: "How to Contact Us",
    paragraph:
      "For any enquiries you have in relation to this Privacy Policy, please feel free to contact our Data Protection Officer at info@yourstudypath.com.",
    orderedLists: [],
    footer: "",
  },
];

export const privacyPolicyHeader = [
  {
    title: "",
    paragraph:
      "Your Study Path Limited (“YSPL”) takes its responsibilities with regard to the management of personal data very seriously and we do not take lightly the trust you have reposed in us, in providing us with your personal information. This Policy describes how YSPL processes your personal data, but it may not address all possible data processing scenarios. This Privacy Policy is a guide to help you understand:",
    orderedLists: [
      "The Information We Collect;",
      " How We Collect and Use Your Data;",
      "Consent and Access Rights;",
      " Personal Data Protection Principles;",
      "User Responsibility;",
      "Data Security;",
      "Third Party Access;",
      "Violation of Privacy; and",
      "How to Contact Us",
    ],
    footer: "",
  },
];
