import React from "react";
import classes from "./HeroSection.module.css";
import heroSectionGirl from "../../Images and Icons/hero section girl.svg";
import play from "../../Images and Icons/play.svg";
import underineScribble from "../../Images and Icons/underline scribble.svg";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  // navigate
  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <div className={classes.leftSection}>
        <h2>
          Learn To Earn The <br />
          Grade Of Your Dreams
          <span>
            <img src={underineScribble} alt="Underline" />
          </span>
        </h2>
        <p>
          Learn and understand the way you wish, pass all your exams in one
          sitting.
        </p>
        <div>
          <button
            onClick={() => {
              window.open("https://app.yourstudypath.com/onboarding", "_blank");
            }}
          >
            Get Started
          </button>
          <span
            onClick={() => {
              navigate("/how-it-works");
            }}
          >
            <img src={play} alt="Play" />
            <span>How it works</span>
          </span>
        </div>
      </div>
      <div className={classes.rightSection}>
        <img src={heroSectionGirl} alt="Student" />
      </div>
    </div>
  );
};

export default HeroSection;
