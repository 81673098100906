import React, { useEffect } from "react";
import classes from "./Testimonials.module.css";
import testimonialUser from "../../Images and Icons/testimonial user.svg";
import Aos from "aos";
import "aos/dist/aos.css";

const Testimonials = () => {
  const testimonials = [
    {
      image: testimonialUser,
      comment:
        "I was way behind in class and was struggling, but with Your Study Path, learning has been fun. The question bank has been really helpful in terms of practicing questions to prepare for my exams.",
      userName: "Chidera Babalola",
      userLevel: "SS3 Student (WAEC)",
    },
  ];

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.header} data-aos="zoom-in-down">
        <h4>Testimonials</h4>
        <p>
          Over 100+ students and parents trust our service, this is what they
          have to say about us.
        </p>
      </div>
      <div className={classes.testimonial}>
        <div className={classes.subTestimonial}>
          <div data-aos="fade-up">
            <img src={testimonials[0].image} alt={testimonials[0].userName} />
          </div>
        </div>
        <div className={classes.subTestimonial} data-aos="fade-down">
          <p>"{testimonials[0].comment}"</p>
          <p>
            {`- ${testimonials[0].userName}, `}
            <span>{testimonials[0].userLevel}</span>
          </p>
          {/* <div>
            <span>READ MORE TESTIMONIES</span>
            <i>
              <FontAwesomeIcon icon={faArrowRight} />
            </i>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
