import React from "react";
import LandingPageLayout from "../LandingPageLayout/LandingPageLayout";
import classes from "./AboutUs.module.css";
import yspteam from "../../Images and Icons/YSP team.svg";
import OurBoardOfAdvisory from "./OurBoardOfAdvisory";

const AboutUs = () => {
  return (
    <LandingPageLayout>
      <div className={classes.container}>
        <div className={classes.containerInner}>
          <div className={classes.header}>
            <div>
              <h4>About Your Study Path</h4>
              <p>
                Our goal is to bridge the discrepancy in the quality of learning
                and tutoring in Nigeria, Africa and the world.
              </p>
            </div>
          </div>
          <div className={classes.body}>
            <div className={classes.bodyInner}>
              <div>
                <img src={yspteam} alt="YSP Team" />
              </div>
              <div>
                <p>
                  We are a high-end Education Technology platform designed to
                  provide students with affordable High quality pre-recorded
                  classes and Materials to ensure every student achieves the
                  grade of their dreams.
                </p>
                <p>
                  We are passionate about investing in the lives of students
                  through the use of educational videos delivered by experts to
                  engage and improve their learning.
                </p>
                <p>
                  By incorporating practical knowledge into learning, we are
                  reviving interest in the subject. We serve as your one-stop
                  solution for curriculum-based education.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.boardofAdvisory}>
          <OurBoardOfAdvisory />
        </div>
      </div>
    </LandingPageLayout>
  );
};

export default AboutUs;
