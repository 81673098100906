import React, { useEffect } from "react";
import classes from "./PrivacyPolicyContainer.module.css";
import {
  privacyPolicy,
  privacyPolicyHeader,
} from "../../../Utilities/PrivacPolicy";
import LandingPageLayout from "../LandingPageLayout/LandingPageLayout";
import Aos from "aos";
import "aos/dist/aos.css";

const PrivacyPolicyContainer = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <LandingPageLayout>
      <div className={classes.container}>
        <div className={classes.containerInner}>
          <div className={classes.header}>
            <div>
              <h4>Privacy Policy</h4>
            </div>
          </div>
          <div className={classes.body}>
            <div className={classes.bodyInner}>
              {privacyPolicyHeader.map((data, i) => {
                return (
                  <div className={classes.bodyMain}>
                    <div key={i} className={classes.subHeading}>
                      <h4 data-aos="fade-up">{data.title}</h4>
                      <p> {data.paragraph}</p>
                      {data.orderedLists.map((data, k) => {
                        return (
                          <div className={classes.orderedLists}>
                            <div>{`(${k + 1})`}</div>
                            <div>{data}</div>
                          </div>
                        );
                      })}
                    </div>
                    <p>{data.footer}</p>
                  </div>
                );
              })}
              {privacyPolicy.map((data, i) => {
                return (
                  <div className={classes.bodyMain}>
                    <div key={i} className={classes.subHeading}>
                      <h4 data-aos="fade-up">{data.title}</h4>
                      <p> {data.paragraph}</p>
                      {data.orderedLists.map((data, j) => {
                        return (
                          <div className={classes.orderedLists}>
                            <div>{`${i + 1}.${j + 1}`}</div>
                            <div>{data}</div>
                          </div>
                        );
                      })}
                    </div>
                    <p>{data.footer}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </LandingPageLayout>
  );
};

export default PrivacyPolicyContainer;
