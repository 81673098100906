import React, { useState, useEffect } from "react";
import { v4 } from "uuid";
import classes from "./PopularCourses.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import MockExamModal from "../../../ReusableComponents/Modal/MockExamModal.js/MockExamModal";
import PopularCourseModal from "./PopularCourseModal";
import Aos from "aos";
import "aos/dist/aos.css";

const PopularCourses = () => {
  const popularVideos = [
    {
      id: v4(),
      tutor: "Mr. Prince",
      role: "Head Mathematics Tutor",
      topic: "English Language (Narrative Essay)",
      description: "Introduction to Narrative Essays",
      videoImage:
        "https://res.cloudinary.com/drybwe5na/image/upload/v1679646143/englishLanguageThumbnail_gwegzn.png",
      link: "752100153",
      yspAppRedirect: "https://app.yourstudypath.com/subjects/English/sections",
    },
    {
      id: v4(),
      tutor: "Arikewuyo Mojeed",
      role: "Head Mathematics Tutor",
      topic: "Mathematics (Geometric Progression)",
      description:
        "Introduction to Geometric Progression which expalains the basic concept.",
      videoImage:
        "https://res.cloudinary.com/drybwe5na/image/upload/v1679646646/mathematicsThumbnail_lrud35.png",
      link: "777965054",
      yspAppRedirect:
        "https://app.yourstudypath.com/subjects/Mathematics/sections",
    },
    {
      id: v4(),
      tutor: "Oluchi Kem-Ajieh",
      role: "Head Accounting Tutor",
      topic: "Financial Accounting (Accounting Process Cycle)",
      description: "Introduction to Account Process Cycle.",
      videoImage:
        "https://res.cloudinary.com/drybwe5na/image/upload/v1679646774/financialAccountingThumbnail_iolkus.png",
      link: "806403344",
      yspAppRedirect:
        "https://app.yourstudypath.com/subjects/Accounting/sections",
    },
  ];

  // States
  const [displayPreviewModal, setDisplayPreviewModal] = useState(false);
  const [activeLink, setActiveLink] = useState("");

  // Effects
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <section className={classes.container}>
      {displayPreviewModal && (
        <MockExamModal
          body={
            <PopularCourseModal
              onClick={() => {
                setDisplayPreviewModal(false);
              }}
              activeLinkId={activeLink}
            />
          }
          onClick={() => {
            setDisplayPreviewModal(false);
          }}
        />
      )}
      <div className={classes.innerContainer}>
        <div className={classes.header} data-aos="zoom-in-down">
          <h4>Popular Courses</h4>
          <p>
            Over 100+ students and parents trust our service, this is what they
            have to say about us.
          </p>
        </div>

        <div className={classes.videoSection}>
          {popularVideos.map((data) => {
            return (
              <div key={data.id} className={classes.videoCard}>
                <div className={classes.pictureSection}>
                  <img src={data.videoImage} alt={data.topic} />
                </div>
                <div className={classes.textSection}>
                  <div className={classes.textUpperSection}>
                    <div className={classes.tutorDescription}>
                      <div></div>
                      <div>{data.tutor}</div>
                    </div>
                    <div className={classes.videoTopic}>{data.topic}</div>
                    <div className={classes.videoDescription}>
                      {data.description}
                    </div>
                  </div>
                  <div className={classes.textLowerSection}>
                    <span
                      onClick={() => {
                        window.open(data.yspAppRedirect, "_blank");
                      }}
                    >
                      View more
                    </span>
                    <span
                      onClick={() => {
                        setDisplayPreviewModal(true);
                        setActiveLink(data.link);
                      }}
                    >
                      <FontAwesomeIcon icon={faPlay} />
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default PopularCourses;
