import { useEffect } from "react";
import LandingPageLayout from "../LandingPageLayout/LandingPageLayout";
import classes from "./HowItWorksPage.module.css";
import Aos from "aos";
import "aos/dist/aos.css";

const HowItWorksPage = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <LandingPageLayout>
      <div className={classes.container}>
        <div className={classes.containerInner}>
          <div className={classes.header}>
            <div>
              <h4>How It Works</h4>
              <p>
                Our goal is to bridge the discrepancy in the quality of learning
                and tutoring in Nigeria, Africa and the world.
              </p>
            </div>
          </div>
          <div className={classes.body}>
            <div className={classes.bodyInner} data-aos="zoom-in-down">
              <iframe
                src="https://www.youtube.com/embed/-ILODOWWC3U"
                title="Navigating YSP Website"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                modestbranding="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </LandingPageLayout>
  );
};

export default HowItWorksPage;
