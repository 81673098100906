import React, { useState, useEffect } from "react";
import classes from "./FAQs.module.css";
import LandingPageLayout from "../LandingPageLayout/LandingPageLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Aos from "aos";
import "aos/dist/aos.css";

const FAQs = () => {
  const [faqs, setFaqs] = useState([
    {
      header: "How will/do i join a class",
      detail:
        "The goal of the platform is to bring learning to you, without the boundaries of the classroom. this is done through our video sessions uploaded for each subject on the platform. However, should you feel a need for a personal session after going through the videos, do make a note beneath the lesson content in the discussion forum.",
      isActive: false,
    },
    {
      header: "I don't know how to register for the quiz",
      detail:
        "The first step would be to subscribe to the platform on one of our plans, either Monthly, Quarterly, or annually. This is the biggest requirement to take part in the quiz competition, alongside you being between the age of 13 to 17. The quiz competition will have two phases. The first phase is online, on the 17th of September, through this Your Study Path (YSP) platform. From there, the top 10 students will be selected and invited in for the second phase which will be a physical examination at the YSP Headquarters.",
      isActive: false,
    },
    {
      header:
        "Is Your Study Path content available for only JAMB and WAEC students",
      detail: "For now, we only have content for JAMB and WAEC students.",
      isActive: false,
    },
    {
      header: "How do I partake in the online quiz?",
      detail:
        "Intellectus is the largest online quiz competition in Nigeria. This competition will have two phases. The first phase is online, on the 17th of September, through this Your Study Path (YSP) platform. Students will be tested in Mathematics, English, and General Paper. From there, the top 10 students will be selected and invited in for the second phase which will be a physical examination at the YSP Headquarters. The first step would be to subscribe to the platform on one of our plans, either Monthly, Quarterly, or annually. This is the biggest requirement to take part in the quiz competition, alongside you being between the age of 13 to 17.",
      isActive: false,
    },

    {
      header: "Areas of concentration in the Intellectus competition?",
      detail:
        "The competition will range from Mathematics, English, and General Paper. The question will be touched on from aspects of the secondary school curriculum, as referenced by the age range specification of 13-17.",
      isActive: false,
    },
    {
      header: "I don't remember my password",
      detail:
        "You can go back to the login page and click on Forgot password, it will go through as long as you still have the email account you used to register.",
      isActive: false,
    },
    {
      header: "Do I pay a subscription fee?",
      detail: "Yes, the subscriptions are paid plans.",
      isActive: false,
    },
    {
      header: "Are there educational competitions one can participate in",
      detail:
        "The Intellectus competition organized by your study path will be held in December. You need to subscribe to any of the plans on this platform which give you ultimate access to all the materials for practice and active preparation for the competition. You also need to follow us on our social media platforms to know the competition timeline.",
      isActive: false,
    },
  ]);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const activeToggler = (index) => {
    const faqCopy = faqs.map((data, i) => {
      if (index === i) {
        console.log("let goo");
        return { ...data, isActive: !data.isActive };
      }
      return { ...data, isActive: data.isActive };
    });
    setFaqs(faqCopy);
  };

  return (
    <LandingPageLayout>
      <div className={classes.container}>
        <div className={classes.containerInner}>
          <div className={classes.header}>
            <div>
              <h4>Frequently Asked Questions</h4>
            </div>
          </div>
          <div className={classes.body}>
            <div className={classes.bodyInner}>
              {faqs.map((data, i) => {
                return (
                  <div
                    key={data.header}
                    className={classes.faq}
                    onClick={() => {
                      activeToggler(i);
                    }}
                    data-aos="zoom-in-up"
                  >
                    <div className={classes.faqHeader}>
                      <h4>{data.header}</h4>
                      <i
                        style={
                          data.isActive
                            ? {
                                transform: "rotate(-90deg)",
                                transition: "all .2s ease-in",
                              }
                            : {
                                transform: "rotate(0deg)",
                                transition: "all .2s ease-in",
                              }
                        }
                      >
                        <FontAwesomeIcon icon={faAngleRight} color="#2855AE" />
                      </i>
                    </div>
                    <div
                      className={classes.faqDetail}
                      id="faqDetail"
                      style={
                        data.isActive === true
                          ? { maxHeight: "200px" }
                          : { maxHeight: "0px" }
                      }
                    >
                      {data.isActive && <span>{data.detail}</span>}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={classes.getInTouch}>
            <div className={classes.getInTouchInner}>
              <div>
                <h4> Still have questions?</h4>
                <p>
                  Can’t find the answer you’re looking for? Please chat with our
                  team.
                </p>
              </div>
              <button
                onClick={() => {
                  window.HubSpotConversations.widget.open();
                }}
              >
                Get in touch
              </button>
            </div>
          </div>
        </div>
      </div>
    </LandingPageLayout>
  );
};

export default FAQs;
