import React from "react";
import classes from "./Header.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import yspLogo from "../../Images and Icons/ysp svg logo.svg";
import hamburgerMenu from "../../Images and Icons/hamburger menu.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faAngleRight } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  // nav
  const navigate = useNavigate();
  const location = useLocation();

  const navLinks = [
    { title: "Home", route: "/" },
    { title: "Plan & Pricing", route: "/plan-and-pricing" },
    { title: "About Us", route: "/about-us" },
    { title: "FAQs", route: "/faqs" },
    { title: "Sponsor a child", route: "https://sponsorachild.yourstudypath.com/" },

    {
      title: "Log In",
      route: "https://app.yourstudypath.com/login",
    },
  ];

  const sideNavOpener = () => {
    document.getElementById("sideNav").style.width = "100%";
  };

  const sideNavCloser = () => {
    document.getElementById("sideNav").style.width = "0%";
  };

  return (
    <div className={classes.container}>
      <div className={classes.leftSection}>
        <img
          style={{
            cursor: "pointer",
          }}
          src={yspLogo}
          alt="YSP Logo"
          onClick={() => {
            navigate("/");
          }}
        />
      </div>
      <div className={classes.rightSection}>
        {navLinks.slice(1).map((data, i) => {
          return (
            <div
              key={data.title}
              className={
                location.pathname.includes(data.route)
                  ? `${classes.activeLink}`
                  : `${classes.inactiveLink}`
              }
            >
              <a href={data.route} target={i === 4 && "_blank"}>
                {data.title}
              </a>
              <span></span>
            </div>
          );
        })}
        <button
          onClick={() => {
            window.open("https://app.yourstudypath.com/onboarding", "_blank");
          }}
        >
          Sign Up
        </button>
      </div>
      <div className={classes.mobileRightSection}>
        <img src={hamburgerMenu} alt="Menu" onClick={sideNavOpener} />
      </div>

      <div className={classes.sideNav} id="sideNav">
        <div className={classes.sidenavInner}>
          <div onClick={sideNavCloser}></div>
          <div>
            <div>
              <img
                src={yspLogo}
                alt="YSP Logo"
                onClick={() => {
                  navigate("/");
                }}
              />
              <i onClick={sideNavCloser}>
                <FontAwesomeIcon
                  icon={faXmark}
                  fontSize="14px"
                  color="#2855AE"
                />
              </i>
            </div>
            <div className={classes.sideNavLinks}>
              {navLinks.map((data, i) => {
                return (
                  <div
                    key={data.title}
                    className={
                      location.pathname.includes(data.route)
                        ? `${classes.activeLink}`
                        : `${classes.inactiveLink}`
                    }
                    // onClick={() => {
                    //   navigate(data.route);
                    // }}
                  >
                    <a href={data.route} target={i === 5 && "_blank"}>
                      {data.title}
                    </a>
                    <span>
                      <FontAwesomeIcon icon={faAngleRight} />
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
