import React, { useState, useEffect } from "react";
import LandingPageLayout from "../LandingPageLayout/LandingPageLayout";
import classes from "./PlanAndPricing.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Aos from "aos";
import "aos/dist/aos.css";

const PlanAndPricing = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const subscriptionDescription1 = [
    "Live videos",
    "Unlimited resources",
    "Mock Exam",
  ];

  const subscriptionDescription2 = [
    "Pre-recorded classes",
    "Live chat with tutors",
    "Earn points to cash",
  ];

  const [plans, setPlans] = useState([
    {
      title: "Monthly Plan",
      description: "billed every month",
      amount: 5000,
      isActive: false,
    },
    {
      title: "Quarterly Plan",
      description: "billed every 3 months",
      amount: 10000,
      isActive: false,
    },
    {
      title: "Annual Plan",
      description: "billed every 12 months",
      amount: 30000,
      isActive: false,
    },
  ]);

  const activeToggler = (initState, setState, id) => {
    const statesCopy = initState.map((data, i) => {
      if (i === id) {
        return { ...data, isActive: !data.isActive };
      }
      return { ...data, isActive: false };
    });
    setState(statesCopy);
  };

  return (
    <LandingPageLayout>
      <div className={classes.container}>
        <div className={classes.containerInner}>
          <div className={classes.header}>
            <div>
              <h4>Simple and Transparent Pricing To Make Learning Fun Again</h4>
            </div>
          </div>
          <div className={classes.body}>
            <div className={classes.bodyInner}>
              <h4>Choose a Plan</h4>
              <p>
                Get unlimited access to live classes, recorded videos and
                quality resources on all topics for SS1-SS3, Waec and Jamb
                syllabus
              </p>
              <div className={classes.subscriptionDescription}>
                <div className={classes.leftSection}>
                  {subscriptionDescription1.map((data) => {
                    return (
                      <div key={data} data-aos="zoom-in-up">
                        <i>
                          <FontAwesomeIcon icon={faCheck} />
                        </i>
                        <p>{data}</p>
                      </div>
                    );
                  })}
                </div>
                <div className={classes.rightSection}>
                  {subscriptionDescription2.map((data) => {
                    return (
                      <div key={data} data-aos="zoom-in-up">
                        <i>
                          <FontAwesomeIcon icon={faCheck} />
                        </i>

                        <p>{data}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={classes.subscriptionSelectSection}>
                {plans.map((data, i) => {
                  return (
                    <div
                      className={
                        // data.isActive
                        //   ? `${classes.planActive}`
                        //   :
                        `${classes.plan}`
                      }
                      key={data.title}
                      onClick={() => {
                        activeToggler(plans, setPlans, i);
                      }}
                      data-aos="fade-up"
                    >
                      <div className={classes.planLeftSection}>
                        <p>{data.title}</p>
                        <p>{data.description}</p>
                      </div>
                      <div className={classes.planRightSection}>
                        <p> {`NGN ${data.amount}`}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingPageLayout>
  );
};

export default PlanAndPricing;
