import React, { useEffect } from "react";
import classes from "./OurFearuresSection.module.css";
import feature1 from "../../Images and Icons/Feature Image 1.png";
import feature2 from "../../Images and Icons/Feature Image 2.svg";
import feature3 from "../../Images and Icons/Feature Image 3.svg";
import Aos from "aos";
import "aos/dist/aos.css";

const OurFearuresSection = () => {
  const features = [
    {
      image: feature1,
      miniHeader: "Our Classes",
      header: "Start learning with vetted experts",
      detail:
        "Classes are taught by well experienced teachers with the aid of live and recorded classes to keep you excited and make learning interesting again.",
      buttonText: "Learn Now",
      route: "https://app.yourstudypath.com",
      aos: "zoom-in-up",
    },
    {
      image: feature2,
      miniHeader: "Our Question Bank",
      header: "Check out materials you need to excel in exams",
      detail:
        "We provide high quality and well detailed study materials with over a thousand questions, each with an in-depth explanation and feedback from teachers so you can practice.",
      buttonText: "Register Now",
      route: "https://app.yourstudypath.com/question-bank",
      aos: "zoom-in-up",
    },
    {
      image: feature3,
      miniHeader: "Our Exams",
      header: "Track your study progress with tests",
      detail:
        "Track your progress with a curriculum standard test that gives you a head start on the exam experience with corrections",
      buttonText: "Take Exam",
      route: "https://app.yourstudypath.com/mock-exam",
      aos: "zoom-in-up",
    },
  ];

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.header} data-aos="zoom-in-down">
        <h4>Our Features</h4>
        <p>
          From video lessons to mock exams, we’re providing you with the best
          resources you need to succeed
        </p>
      </div>
      <div className={classes.features}>
        {features.map((data) => {
          return (
            <div className={classes.featuresContainer} data-aos={data.aos}>
              <div className={classes.leftSection}>
                <img src={data.image} alt="Feature" />
              </div>
              <div className={classes.rightSection}>
                <div>
                  <span>
                    <hr />
                  </span>
                  <span>{data.miniHeader}</span>
                </div>
                <h4>{data.header}</h4>
                <p>{data.detail}</p>
                <button
                  onClick={() => {
                    // window.location.href = "https://app.yourstudypath.com";
                    window.open(data.route, "_blank");
                  }}
                >
                  {data.buttonText}
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OurFearuresSection;
